import React, { useEffect, useState, useContext } from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import { AuthContext } from "./../components/context/Auth";

const AuthRoute = (props) => {
  const { ...rest } = props;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const checkUserToken = () => {
    if (!currentUser || currentUser === null) {
      setIsLoggedIn(false);
    }
    setIsLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
    console.log("curreuser", currentUser);
  }, [isLoggedIn]);

  return (
    <React.Fragment>
      {currentUser ? <Route {...rest} /> : <Redirect to={"/"} />}
    </React.Fragment>
  );
};
export default withRouter(AuthRoute);

// import React, { useContext } from "react";
// import { Route, withRouter } from "react-router-dom";
// import { AuthContext } from "./../components/context/Auth";

// const AuthRoute = (props) => {
//   const { currentUser } = useContext(AuthContext);
//   const { ...rest } = props;

//   return (
//     <React.Fragment>
//       {currentUser ? <Route {...rest} /> : <Route exact={true} path="/" />}
//     </React.Fragment>
//   );
// };

// export default withRouter(AuthRoute);
