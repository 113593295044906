import React from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";

function Token() {
  return (
    <>
      <HeaderTwo />
      <main
        className="mt-55"
        style={{ backgroundColor: "#1e1b20", paddingTop: "3em" }}
      >
        <section className="upcoming-games-area pb-140">
          <div className="container mb-55 mt-55">
            <div className="row">
              <div className="col-12">
                <div className="game-single-title mb-55">
                  <center>
                    <h1>
                      <span>Playarea Ecosystem</span>
                    </h1>
                  </center>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="token-features mb-30">
                  <h4>Portal</h4>
                  <h6 className=" mb-30" style={{ color: "#e4a101" }}>
                    IGO/INO tickets
                  </h6>
                  <p>
                    Users must stake AREA to enter the lottery in order to
                    participate in IGO/INO in the future.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="token-features mb-30">
                  <h4>Protocol</h4>
                  <h6 className=" mb-30" style={{ color: "#e4a101" }}>
                    One payment currency
                  </h6>
                  <p>
                    AREA will be recognized as the only payment currency on the
                    PlayArea ecosystem to run a Node/purchase game NFTs.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="token-features mb-30">
                  <h4>Governance</h4>
                  <h6 className=" mb-30" style={{ color: "#e4a101" }}>
                    PlayArea token governance
                  </h6>
                  <p>
                    Project treasury will be governed by Node operators and
                    Holders of Area tokens in the future.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="token-features mb-30">
                  <h4>Game Studios</h4>
                  <h6 className=" mb-30" style={{ color: "#e4a101" }}>
                    New games drop
                  </h6>
                  <p>
                    For every game built/Launched by PlayArea Studios, AREA Node
                    operators can earn NFTs and stand a chance for the Airdrop
                    of new game token.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="token-features mb-30">
                  <h4>Games</h4>
                  <h6 className=" mb-30" style={{ color: "#e4a101" }}>
                    Developers incentives
                  </h6>
                  <p>
                    Games developers in the PlayArea will need to stake AREA for
                    integration into our ecosystem.Users must stake AREA to
                    enter the lottery in order to participate in IGO/INO in the
                    future
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="game-single-title mb-55 mt-55">
                  <center>
                    <h1>
                      <span>Token Distribution and Issuance for PlayArea</span>
                    </h1>
                  </center>
                </div>
              </div>
            </div>
            <h5 className="mb-55" style={{ lineSpacing: "1.5em" }}>
              Area, the native token of PlayArea, is a fundamental utility token
              that adheres to the ERC20 standard. Area serves as the principal
              fungible token for the Playarea Ecosystem's transaction
              processing. After PlayArea deducts a modest transaction charge
              from each in-game purchase or exchange, the remaining Area is
              distributed to the Web3 Games Host or producers.
            </h5>
            <div className="row">
              <div className="col-12">
                <div className="game-single-title mb-55">
                  <center>
                    <h1>
                      <span>Tokenomics</span>
                    </h1>
                  </center>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="latest-match-box mb-30">
                  <div
                    className="tournament-schedule-content"
                    style={{ width: "100%" }}
                  >
                    <h3>
                      Total <span> Supply</span>
                    </h3>
                    <h4>1 billion</h4>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6">
                {" "}
                <div className="latest-match-box mb-30">
                  <div
                    className="tournament-schedule-content"
                    style={{ width: "100%" }}
                  >
                    <h3>
                      Max <span> Supply</span>
                    </h3>
                    <h4>Undefined</h4>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="latest-match-box mb-30">
                  <div
                    className="tournament-schedule-content"
                    style={{ width: "100%" }}
                  >
                    <h3>
                      PlayArea <span> Ecosystem</span>
                    </h3>
                    <h4>24%</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="latest-match-box mb-30">
                  <div
                    className="tournament-schedule-content"
                    style={{ width: "100%" }}
                  >
                    <h3>
                      Sale<span> Supply</span>
                    </h3>
                    <h4>20%</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="latest-match-box mb-30">
                  <div
                    className="tournament-schedule-content"
                    style={{ width: "100%" }}
                  >
                    <h3>
                      Reserve <span></span>
                    </h3>
                    <h4>15%</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="latest-match-box mb-30">
                  <div
                    className="tournament-schedule-content"
                    style={{ width: "100%" }}
                  >
                    <h3>
                      Marketing<span></span>
                    </h3>
                    <h4>18%</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="latest-match-box mb-30">
                  <div
                    className="tournament-schedule-content"
                    style={{ width: "100%" }}
                  >
                    <h3>
                      Team<span></span>
                    </h3>
                    <h4>17%</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="latest-match-box mb-30">
                  <div
                    className="tournament-schedule-content"
                    style={{ width: "100%" }}
                  >
                    <h3>
                      Partners<span> \Advisors</span>
                    </h3>
                    <h4>4%</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {" "}
                <div className="latest-match-box mb-30">
                  <div
                    className="tournament-schedule-content"
                    style={{ width: "100%" }}
                  >
                    <h3>
                      Airdrop<span></span>
                    </h3>
                    <h4>2%</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Token;
