import React, { useEffect, useState } from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";
import { Button, Spinner } from "reactstrap";

function Confirm() {
  const [email, setEmail] = useState(localStorage.getItem("playUser"));
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(true);
  const [verifyCode, setVerifyCode] = useState("");
  let history = useHistory();

  const onConfirm = () => {
    if (email === "" || verifyCode === "") {
      toast.error("Please enter email or code", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(true);
      const formData = {
        email,
        verifyCode,
      };
      try {
        const url = `${process.env.REACT_APP_SERVER_API_URL}/verify_email`;
        const options = {
          method: "POST",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(formData),
          url,
        };
        axios(options)
          .then((response) => {
            if (response.data.success) {
              console.log(response.data);
              toast.success(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              // setCurrentUser(response.data.body);
              setTimeout(() => {
                history.push("/auth/login");
                setLoading(false);
              }, 3000);
            } else {
              setLoading(false);
              toast.error(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              console.log(response);
            }
          })
          .catch((err) => {
            toast.error(`a server error occurred ${err}`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          });
      } catch (err) {
        toast.error(`a server error occurred ${err}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setTimeout(() => {
      setHide(false);
    }, 100000);
  }, []);

  return (
    <>
      <HeaderTwo />
      <main>
        <section className="blog-area primary-bg pt-200 pb-175">
          <div className="container">
            <center>
              {" "}
              <div className="col-lg-8">
                <div className="comment-reply-box">
                  <div className="sidebar-widget-title blog-details-title mb-35">
                    <h4>
                      Confirm <span>Account</span>
                    </h4>
                  </div>
                  <label htmlFor="comment-check">
                    We sent an OTP to your email address "{email}"
                  </label>
                  <form className="comment-form" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12 mb-25">
                        <input
                          type="text"
                          placeholder="Enter OTP here*"
                          value={verifyCode}
                          onChange={(e) => setVerifyCode(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="comment-check-box mb-25">
                      {hide ? (
                        <></>
                      ) : (
                        <label htmlFor="comment-check">
                          Resend OTP? in 1.00 min
                        </label>
                      )}
                    </div>
                    {loading ? (
                      <Button color="warning" disabled>
                        <Spinner size="sm">Authenticating...</Spinner>
                        <span> Authenticating</span>
                      </Button>
                    ) : (
                      <button className="btn btn-style-two" onClick={onConfirm}>
                        Verify
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </center>
          </div>
        </section>
      </main>

      <ToastContainer />
      <Footer />
    </>
  );
}

export default Confirm;
