import React from "react";

function Footer() {
  return (
    <footer>
      <div className="footer-top footer-bg">
        {/* newsletter-area */}
        <div className="newsletter-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="newsletter-wrap">
                  <div className="section-title newsletter-title">
                    <h2>
                      Waiting <span>List</span>
                    </h2>
                  </div>
                  <div className="newsletter-form">
                    <form>
                      <div className="newsletter-form-grp">
                        <i className="far fa-envelope" />
                        <input type="email" placeholder="Enter your email..." />
                      </div>
                      <button>
                        Join now <i className="fas fa-paper-plane" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* newsletter-area-end */}
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="footer-widget mb-50">
                <div className="footer-logo mb-35">
                  <a href="/">
                    <img
                      src="https://imgur.com/inPRXLv.png"
                      alt="playarea logo"
                      width="20%"
                      height="20%"
                    />
                  </a>
                </div>
                <div className="footer-text">
                  <p>A unifying ecosystem for all web3 games</p>
                  <div className="footer-contact">
                    <ul>
                      <li>
                        <i className="fas fa-envelope-open" />
                        <span>Email : </span>info@playarea.com
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-sm-6">
              <div className="footer-widget mb-50">
                <div className="fw-title mb-35">
                  <h5>Products</h5>
                </div>
                <div className="fw-link">
                  <ul>
                    <li>
                      <a href="/game/fearea">Games</a>
                    </li>
                    <li>
                      <a href="/store">NFT's </a>
                    </li>
                    <li>
                      <a href="/node">Buy Node</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-sm-6">
              <div className="footer-widget mb-50">
                <div className="fw-title mb-35">
                  <h5>Need Help?</h5>
                </div>
                <div className="fw-link">
                  <ul>
                    <li>
                      <a href="/#">Terms &amp; Conditions</a>
                    </li>
                    <li>
                      <a href="/#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/#">Data Policy</a>
                    </li>
                    <li>
                      <a href="/#">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="footer-widget mb-50">
                <div className="fw-title mb-35">
                  <h5>Follow us</h5>
                </div>
                <div className="footer-social">
                  <ul>
                    {/* <li>
                      <a href="/#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="https://twitter.com/PlayAreaGame"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://t.me/PlayAreaGames"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fas fa-paper-plane" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-fire">
          <img src="assets/img/images/footer_fire.png" alt="" />
        </div>
        <div className="footer-fire footer-fire-right">
          <img src="assets/img/images/footer_fire.png" alt="" />
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="copyright-text">
                <center>
                  <p>
                    Copyright © 2024 <a href="/#">Playarea</a> All Rights
                    Reserved.
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
