import React from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
// import Breadcrumb from "../upcoming/Breadcrumb";
import SingleArea from "./SingleArea";
import ReleaseGame from "./ReleaseGame";
import { Link } from "react-router-dom";

function GameSingle() {
  return (
    <>
      <HeaderTwo />
      <main>
        <section className="breadcrumb-area breadcrumb-bg s-breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb-content text-center">
                  {/* <h2>
                    Fearea <span>Game</span>
                  </h2> */}
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">game</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Games
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SingleArea />
        <ReleaseGame />
      </main>
      <Footer />
    </>
  );
}

export default GameSingle;
