import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "./../../../components/context/Auth";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "./Dashboard.css";
import Footer from "../../../components/footer/Footer";
import { Button, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";

const Balances = () => {
  //  States
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  let history = useHistory();

  const onUpdateName = () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_SERVER_API_URL}/dashboard/token_accounts`;
      const options = {
        method: "GET",
        headers: {
          Authorization: currentUser,
          "content-type": "application/x-www-form-urlencoded",
        },
        url,
      };
      axios(options)
        .then((response) => {
          if (response.data.success === true) {
            setBalance(response.data.data);
            toast.success(response.data.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(response.data.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            console.log(response);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        });
    } catch (err) {
      toast.error(`a server error occurred ${err}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    onUpdateName();
  }, [balance]);

  return (
    <>
      {/* <HeaderTwo /> */}
      <div className="dashboard d-flex">
        <div>
          <Sidebar />
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <Navbar />
          <div style={{ height: "100%" }}>
            <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
              <div className="d-flex card-section">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="blog-list-post" id="name">
                        <div className="blog-list-post-content">
                          <div className="blog-list-post-tag mb-25">
                            <center>
                              <div className="section-title newsletter-title mt-40">
                                {" "}
                                <h2>Wallet Balance</h2>
                              </div>
                              <p>
                                Your Wallet ballance will show here. We
                                currently accept only PlayArea Token (AREA) and
                                Ethereum (ETH)
                              </p>
                            </center>
                          </div>
                          <form
                            className="comment-form"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              <div className="col-md-12 mb-25">
                                <div className="community-wrap">
                                  <div className="table-responsive-xl">
                                    <table className="table mt-0">
                                      <thead>
                                        <tr>
                                          <th scope="col">Token</th>
                                          <th scope="col">Amount</th>
                                          <th scope="col">Value</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th scope="row">
                                            <div className="community-post-wrap">
                                              <div className="community-post-content">
                                                Play Area Token
                                              </div>
                                              <div className="community-post-tag">
                                                <a href="#"> AREA</a>
                                              </div>
                                            </div>
                                          </th>
                                          <td>{balance}</td>
                                          <td className="community-bell">
                                            $200
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">
                                            <div className="community-post-wrap">
                                              <div className="community-post-content">
                                                Ethereum
                                              </div>
                                              <div className="community-post-tag">
                                                <a href="#">ETH</a>
                                              </div>
                                            </div>
                                          </th>
                                          <td>{balance}</td>
                                          <td className="community-bell">
                                            $300
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <center>
                                {loading ? (
                                  <Button color="warning" disabled>
                                    <Spinner size="sm">Refreshing...</Spinner>
                                    <span> Refreshing</span>
                                  </Button>
                                ) : (
                                  <Button
                                    color="warning"
                                    style={{
                                      textTransform: "capitalize",
                                      fontWeight: "bold",
                                      fontSize: "1.2em",
                                      paddingTop: ".6em",
                                      paddingBottom: ".6em",
                                      paddingLeft: "2em",
                                      paddingRight: "2em",
                                    }}
                                    onClick={onUpdateName}
                                  >
                                    Refresh
                                  </Button>
                                )}
                              </center>
                            </div>
                          </form>
                        </div>
                        <div className="blog-list-post-bottom"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer style={{ zIndex: "100" }} />
    </>
  );
};

export default Balances;
