import React, { useEffect, useState, useContext } from "react";
import { Header } from "./Navbar.style";
import { CDBNavbar } from "cdbreact";
import { Button } from "reactstrap";
import { useHistory, Redirect } from "react-router-dom";
import { AuthContext } from "./components/context/Auth";

const Navbar = () => {
  const { currentUser } = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [displayName, setDisplayName] = useState(
    localStorage.getItem("playDisplayName")
  );
  const history = useHistory();

  const checkUser = () => {
    if (!currentUser || currentUser === null) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  };

  const logout = () => {
    localStorage.removeItem("playUser");
    localStorage.removeItem("playToken");
    localStorage.removeItem("playDisplayName");
    window.location.href = "/";
  };

  useEffect(() => {
    checkUser();
  }, [isLoggedIn, displayName]);

  return (
    <Header style={{ background: "#252525", color: "#ffffff" }}>
      <CDBNavbar dark expand="md" scrolling className="justify-content-start">
        {/* <CDBInput
          type="search"
          size="md"
          hint="Search"
          className="mb-n4 mt-n3 input-nav"
        /> */}
        {isLoggedIn ? (
          <>
            {" "}
            <div className="ml-auto">
              <i className="fa fa-user" aria-hidden="true">
                <span style={{ margin: ".4em" }}>{displayName}</span>
              </i>{" "}
              <Button color="danger" onClick={logout}>
                <i className="fa fa-power-off" aria-hidden="true"></i>{" "}
                <span style={{ fontWeight: "2em" }}>Logout</span>
              </Button>
            </div>
          </>
        ) : (
          <>
            <i className="fa fa-user" aria-hidden="true"></i>{" "}
          </>
        )}
      </CDBNavbar>
    </Header>
  );
};

export default Navbar;
