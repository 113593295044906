import React from "react";

function LatestNode() {
  return (
    <section
      className="latest-match-area latest-match-bg pt-115 pb-90"
      style={{
        backgroundImage: `url("https://i.imgur.com/TYfgHYq.jpg")`,
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-three white-title text-center mb-70 mt-70">
              <h2>
                Purchase a Creator's <span>Node Now!</span>
              </h2>
              <p>
                Activate channels to obtain incentives such as AREA and
                exclusive NFTs, as well as the opportunity to contribute to the
                growth of the PlayArea Games ecosystem.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="latest-match-box mb-30">
              <div className="latest-match-thumb">
                <img src="https://i.imgur.com/AL8ggog.jpg" alt="fearea node" />
              </div>
              <div
                className="tournament-schedule-content"
                style={{ width: "100%" }}
              >
                <h3>
                  <a href="/#">
                    Fearea Creator<span> Node</span>
                  </a>
                </h3>
                <p>
                  You and other users like you run PlayArea nodes on their
                  personal computers to help sustain the PlayArea Ecosystem. You
                  can earn incentives for your participation in the PlayArea
                  network by purchasing a license to run a PlayArea Node. This
                  may involve receiving AREA, rare NFTs, or chances to
                  contribute to the development of the PlayArea Games ecosystem.
                  Please join us on Telegram to discuss this further.
                </p>
                <div className="tournament-schedule-meta">
                  <h5>
                    price : <span>125,000 AREA</span>
                  </h5>
                  <a href="/#">Buy</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LatestNode;
