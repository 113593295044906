import React, { useEffect } from "react";
import WOW from "wowjs";
import HeaderTwo from "../../../components/header/HeaderTwo";
import Footer from "../../../components/footer/Footer";
import Breadcrumb from "./Breadcrumb";
// import AboutInner from "./AboutInner";
// import LatestMatch from "./LatestMatch";
// import TeamMem from "./TeamMem";

function AboutUs() {
  useEffect(() => {
    new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
      loop: Infinity,
    }).init();
  }, []);
  return (
    <>
      <HeaderTwo />
      <main>
        <Breadcrumb />
        <section className="inner-about-area fix">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <center>
                  <div className="section-title  mb-25">
                    <h2 style={{ color: "black" }}>
                      About <span>Us</span>
                    </h2>
                  </div>{" "}
                  <div className="inner-about-content">
                    {/* <h5>Monica Global Publishing for Marketing</h5> */}
                    <p>
                      Creating innovative, crowd-pleasing games for the Web3
                      ecosystem while ushering in the next wave of gaming and
                      digital technology.
                    </p>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </section>
        <section className="inner-about-area fix">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6">
                <center>
                  <div className="upcoming-game-thumb">
                    <img
                      src="https://imgur.com/2yfRW7F.png"
                      alt="playarea games"
                      width="10%"
                      height="10%"
                      style={{
                        width: "50%",
                        height: "50%",
                      }}
                    />
                  </div>
                </center>
              </div>
              <div className="col-xl-6 col-lg-6">
                <center>
                  <div className="section-title mt-20 mb-25">
                    <h2 style={{ color: "black" }}>
                      Players' <span>Property</span>
                    </h2>
                  </div>{" "}
                  <div className="inner-about-content">
                    <p>
                      In our games, the prizes won by the players are theirs to
                      keep. Any mystical weapon you get through PlayArea, either
                      it by purchase or otherwise, is legally yours to keep. As
                      a verified asset on the blockchain, your possessions and
                      any inherited ranks can be sold, bartered, or used as
                      currency in our games
                    </p>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </section>
        <section className="inner-about-area fix">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6">
                <center>
                  <div className="section-title  mb-25">
                    <h2 style={{ color: "black" }}>
                      Design <span>&</span> Strategy
                    </h2>
                  </div>{" "}
                  <div className="inner-about-content">
                    <p>
                      Through ongoing interaction and analysis with our
                      community, we ensure that our core beliefs remain sound.
                      We collaborate with an active player base whose opinions
                      and suggestions inform the development of every game we
                      create.
                    </p>
                  </div>
                </center>
              </div>
              <div className="col-xl-6 col-lg-6">
                <center>
                  <div className="upcoming-game-thumb">
                    <img
                      src="https://imgur.com/m2qaeiN.png"
                      alt="playarea games"
                      width="50%"
                      height="50%"
                      style={{
                        width: "50%",
                        height: "50%",
                      }}
                    />
                  </div>
                </center>
              </div>
            </div>
          </div>
        </section>
        <section className="inner-about-area fix">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6">
                <center>
                  <div className="upcoming-game-thumb">
                    <img
                      src="https://imgur.com/v0PtRcS.png"
                      alt="playarea games"
                      width="50%"
                      height="50%"
                      style={{
                        width: "50%",
                        height: "50%",
                      }}
                    />
                  </div>
                </center>
              </div>
              <div className="col-xl-6 col-lg-6">
                <center>
                  <div className="section-title mt-20 mb-25">
                    <h2 style={{ color: "black" }}>
                      Owned <span>&</span> Driven
                    </h2>
                  </div>{" "}
                  <div className="inner-about-content">
                    <p>
                      Ultimately, the Node Ecosystem, which is controlled by the
                      players, will have authority over everything in the
                      PlayArea Games Network. Individuals like yourself who run
                      or own PlayArea Nodes are the backbone of the PlayArea
                      Ecosystem
                    </p>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default AboutUs;
