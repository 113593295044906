import React from "react";
// import { Link, useHistory } from "react-router-dom";

import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "./Dashboard.css";
import Footer from "../../../components/footer/Footer";
// import { Button, Spinner } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";
// import qs from "qs";

const Faq = () => {
  //  States
  // const [name, setName] = useState("");
  // const [loading, setLoading] = useState(false);
  // // const [password, setPassword] = useState("");
  // let history = useHistory();

  // const onUpdateName = () => {
  //   if (name === "") {
  //     toast.error("Please enter a name", {
  //       position: "top-center",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   } else {
  //     setLoading(true);
  //     const formData = {
  //       displayName: name,
  //     };
  //     try {
  //       const url = "https://playarea-api.herokuapp.com/api/v1/login";
  //       // const url = "xxxxxx";
  //       const options = {
  //         method: "POST",
  //         headers: {
  //           "content-type": "application/x-www-form-urlencoded",
  //         },
  //         data: qs.stringify(formData),
  //         url,
  //       };
  //       axios(options)
  //         .then((response) => {
  //           if (response.data.success === true) {
  //             toast.success(response.data.msg, {
  //               position: "top-center",
  //               autoClose: 5000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //               progress: undefined,
  //             });
  //           } else {
  //             setLoading(false);
  //             toast.error(response.data.msg, {
  //               position: "top-center",
  //               autoClose: 5000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //               progress: undefined,
  //             });
  //             console.log(response);
  //           }
  //         })
  //         .catch((err) => {
  //           toast.error(err.response.data.msg, {
  //             position: "top-center",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });
  //           setLoading(false);
  //         });
  //     } catch (err) {
  //       toast.error(`a server error occurred ${err}`, {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       setLoading(false);
  //     }
  //   }
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };

  return (
    <>
      {/* <HeaderTwo /> */}
      <div className="dashboard d-flex">
        <div>
          <Sidebar />
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <Navbar />
          <div style={{ height: "100%" }}>
            <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
              <div className="d-flex card-section">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12" style={{ marginTop: "2rem" }}>
                      <div className="blog-list-post" id="name">
                        <div className="blog-list-post-content">
                          <div className="blog-list-post-tag mb-25">
                            <center>
                              <div className="section-title newsletter-title">
                                {" "}
                                <h2>Frequently Asked Question</h2>
                              </div>
                              <p>
                                Frequently asked questions will be displayed
                                here.
                              </p>
                            </center>
                          </div>
                          {/* <form
                            className="comment-form"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              <div className="col-md-12 mb-25">
                                <input
                                  type="text"
                                  placeholder="Enter a name"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  required
                                />
                              </div>

                              <center>
                                {loading ? (
                                  <Button color="warning" disabled>
                                    <Spinner size="sm">Updating...</Spinner>
                                    <span> Updating</span>
                                  </Button>
                                ) : (
                                  <Button
                                    color="warning"
                                    style={{
                                      textTransform: "capitalize",
                                      fontWeight: "bold",
                                      fontSize: "1.2em",
                                      paddingTop: ".6em",
                                      paddingBottom: ".6em",
                                      paddingLeft: "2em",
                                      paddingRight: "2em",
                                    }}
                                    onClick={onUpdateName}
                                  >
                                    Update
                                  </Button>
                                )}
                              </center>
                            </div>
                          </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer style={{ zIndex: "100" }} />
    </>
  );
};

export default Faq;
