import React, { useEffect, useState } from "react";
// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Spinner } from "reactstrap";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const user = localStorage.getItem("playToken");

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
      setPending(false);
    } else {
      setCurrentUser(user);
      setPending(false);
    }

    console.log("cU", currentUser);
    console.log("u", user);
  }, [user]);

  if (pending) {
    return (
      <>
        <center>
          <Spinner>Loading...</Spinner>
        </center>
      </>
    );
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
