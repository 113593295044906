//get nfts based on category
export const getSortedNfts = (nfts, searchGames, searchRarity) => {
  if (nfts && searchGames) {
    if (searchGames === "Fearea") {
      return nfts.filter((product) => product.game === "Fearea");
    }
    if (searchGames === "Playarea") {
      return nfts.filter((product) => product.game === "Playarea");
    }
    if (searchRarity === "https://imgur.com/kCI9XDR.png") {
      return nfts.filter(
        (product) => product.rarity === "https://imgur.com/kCI9XDR.png"
      );
    }
    if (searchRarity === "https://imgur.com/eKjRteB.png") {
      return nfts.filter(
        (product) => product.rarity === "https://imgur.com/eKjRteB.png"
      );
    }
    if (searchRarity === "https://imgur.com/rGSwNg4.png") {
      return nfts.filter(
        (product) => product.rarity === "https://imgur.com/rGSwNg4.png"
      );
    }
  }
  return nfts;
};
