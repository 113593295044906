import React, { useState, useContext } from "react";
import { AuthContext } from "./../../../components/context/Auth";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "./Dashboard.css";
import Footer from "../../../components/footer/Footer";
import { Button, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";

const Pin = () => {
  //  States
  const [pin, setPin] = useState("");
  const [oldPin, setOldPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const onUpdatePin = () => {
    if (pin === "" || confirmPin === "") {
      toast.error("Please enter a transaction pin", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (pin !== confirmPin) {
      toast.error("Pin did not match", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(true);
      const formData = {
        pin,
        confirmPin,
        oldPin,
      };
      try {
        const url = `${process.env.REACT_APP_SERVER_API_URL}/dashboard/transaction_pin`;
        const options = {
          method: "POST",
          headers: {
            Authorization: currentUser,
            "content-type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(formData),
          url,
        };
        axios(options)
          .then((response) => {
            if (response.data.success === true) {
              toast.success(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setLoading(false);
            } else {
              setLoading(false);
              toast.error(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              console.log(response);
            }
          })
          .catch((err) => {
            toast.error(err.response.data.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          });
      } catch (err) {
        toast.error(`a server error occurred ${err}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {/* <HeaderTwo /> */}
      <div className="dashboard d-flex">
        <div>
          <Sidebar />
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <Navbar />
          <div style={{ height: "100%" }}>
            <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
              <div className="d-flex card-section">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12" style={{ marginTop: "2rem" }}>
                      <div className="blog-list-post" id="name">
                        <div className="blog-list-post-content">
                          <div className="blog-list-post-tag mb-25">
                            <center>
                              <div className="section-title newsletter-title">
                                {" "}
                                <h2>Transaction Pin</h2>
                              </div>
                              <p>
                                To update your Transaction pin, please enter
                                your old pin or default pin <b>0000</b>(if you
                                have not set your pin initially) and enter a new
                                4 digit pin.
                              </p>
                            </center>
                          </div>
                          <form
                            className="comment-form"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              <div className="col-md-12 mb-25">
                                <input
                                  type="password"
                                  placeholder="Enter old or default pin"
                                  value={oldPin}
                                  onChange={(e) => setOldPin(e.target.value)}
                                  required
                                  maxLength={4}
                                />
                              </div>
                              <div className="col-md-12 mb-25">
                                <input
                                  type="password"
                                  placeholder="Enter a new 4 digit pin"
                                  value={pin}
                                  onChange={(e) => setPin(e.target.value)}
                                  required
                                  maxLength={4}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-25">
                                <input
                                  type="password"
                                  placeholder="Enter a new 4 digit pin"
                                  value={confirmPin}
                                  onChange={(e) =>
                                    setConfirmPin(e.target.value)
                                  }
                                  required
                                  maxLength={4}
                                />
                              </div>

                              <center>
                                {loading ? (
                                  <Button color="warning" disabled>
                                    <Spinner size="sm">Updating...</Spinner>
                                    <span> Updating</span>
                                  </Button>
                                ) : (
                                  <Button
                                    color="warning"
                                    style={{
                                      textTransform: "capitalize",
                                      fontWeight: "bold",
                                      fontSize: "1.2em",
                                      paddingTop: ".6em",
                                      paddingBottom: ".6em",
                                      paddingLeft: "2em",
                                      paddingRight: "2em",
                                    }}
                                    onClick={onUpdatePin}
                                  >
                                    Update
                                  </Button>
                                )}
                              </center>
                            </div>
                          </form>
                        </div>
                        <div className="blog-list-post-bottom"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer style={{ zIndex: "100" }} />
    </>
  );
};

export default Pin;
