import React from "react";
import HeaderTwo from "../../../components/header/HeaderTwo";
import Footer from "../../../components/footer/Footer";
import Breadcrumb from "./Breadcrumb";
import LatestNode from "./LatestNode";

function Node() {
  return (
    <>
      <HeaderTwo />
      <main>
        {/* <Breadcrumb /> */}
        <LatestNode />
      </main>
      <Footer />
    </>
  );
}

export default Node;
