import React, { useState } from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";
import { Button, Spinner } from "reactstrap";

function ForgotPassword() {
  // Loading States
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const onForgotPassword = () => {
    if (email === "") {
      toast.error("Please enter email or password", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(true);
      const formData = {
        email,
      };
      try {
        const url = `${process.env.REACT_APP_SERVER_API_URL}/recover_account`;
        const options = {
          method: "POST",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(formData),
          url,
        };
        axios(options)
          .then((response) => {
            if (response.data.success === true) {
              console.log(response.data);
              localStorage.setItem("playUser", email);
              toast.success(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              // setCurrentUser(response.data.body);
              setTimeout(() => {
                history.push("/auth/complete_recovery");
                setLoading(false);
              }, 3000);
            } else {
              setLoading(false);
              toast.error(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              console.log(response);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          });
      } catch (err) {
        toast.error(`a server error occurred ${err}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <HeaderTwo />
      <main>
        <section className="blog-area primary-bg pt-200 pb-175">
          <div className="container">
            <center>
              {" "}
              <div className="col-lg-8">
                <div className="comment-reply-box">
                  <div className="sidebar-widget-title blog-details-title mb-35">
                    <h4>
                      Recover <span> Account</span>
                    </h4>
                  </div>
                  <label>Enter your email address to continue</label>
                  <form className="comment-form" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12 mb-25">
                        <input
                          type="email"
                          placeholder="Email Address*"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    {/* <div className="comment-check-box mb-25">
                      <label htmlFor="comment-check">Resend OTP?</label>
                    </div> */}

                    {loading ? (
                      <Button color="warning" disabled>
                        <Spinner size="sm">Loading...</Spinner>
                        <span> Loading</span>
                      </Button>
                    ) : (
                      <button
                        className="btn btn-style-two"
                        onClick={onForgotPassword}
                      >
                        Continue
                      </button>
                    )}
                  </form>
                  <p className="pt-25 mt-20">
                    Not yet a member? <Link to="/auth/register">Sign Up</Link>
                  </p>
                </div>
              </div>
            </center>
          </div>
        </section>
      </main>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default ForgotPassword;
