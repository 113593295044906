import React from "react";
function GamesAreatwo() {
  return (
    <section className="just-gamers-area just-gamers-bg pt-115 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-10">
            <div className="section-title title-style-three white-title mb-70">
              <h2>
                Built For <span>Gamers</span>
              </h2>
              <p>
                We have developed a web3 gaming hub with advanced, user-friendly
                features for the gaming industry.
              </p>
            </div>
            <div className="just-gamers-list">
              <ul>
                <li>
                  <div className="just-gamers-list-icon">
                    <img src="assets/img/icon/gamer_list_icon01.png" alt="" />
                  </div>
                  <div className="just-gamers-list-content fix">
                    <h5>Players' property</h5>
                    <p>
                      In our games, the prizes won by the players are theirs to
                      keep. Any mystical weapon you get through PlayArea, either
                      it by purchase or otherwise, is legally yours to keep. As
                      a verified asset on the blockchain, your possessions and
                      any inherited ranks can be sold, bartered, or used as
                      currency in our games
                    </p>
                  </div>
                </li>

                <li>
                  <div className="just-gamers-list-icon">
                    <img src="assets/img/icon/gamer_list_icon03.png" alt="" />
                  </div>
                  <div className="just-gamers-list-content fix">
                    <h5>Owned &amp; Driven</h5>
                    <p>
                      Ultimately, the Node Ecosystem, which is controlled by the
                      players, will have authority over everything in the
                      PlayArea Games Network. Individuals like yourself who run
                      or own PlayArea Nodes are the backbone of the PlayArea
                      Ecosystem
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6 d-none d-lg-block">
            <div className="just-gamers-img">
              <img
                src="assets/img/images/just_gamers_img.png"
                alt=""
                className="just-gamers-character"
              />
              <div className="just-gamers-circle-shape">
                <ul>
                  {" "}
                  <li>
                    <div className="just-gamers-list-icon">
                      <img src="assets/img/icon/gamer_list_icon02.png" alt="" />
                    </div>
                    <div className="just-gamers-list-content fix">
                      <h5>Design &amp; Strategy</h5>
                      <p>
                        Through ongoing interaction and analysis with our
                        community, we ensure that our core beliefs remain sound.
                        We collaborate with an active player base whose opinions
                        and suggestions inform the development of every game we
                        create.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GamesAreatwo;
