import React from "react";

function ReleaseGame() {
  return (
    <section className="upcoming-games-area pb-140">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="game-single-title mb-55">
              <h4>
                Fearea <span>GAMES</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>SEPTEMBER 22, 2022</span>
                  <h4>
                    <a href="/#">zombie land</a>
                  </h4>
                </div>
                <div className="uc-game-price">
                  <h5>$19</h5>
                </div>
              </div>
              <p>
                Compete with players remote island winner takes showdown known
                issue.
              </p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/images/upcoming_game_thumb01.jpg" alt="" />
                <div className="upcoming-game-cart">
                  <a href="/#" className="btn transparent-btn">
                    <i className="fas fa-shopping-basket" />
                    BUY Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>SEPTEMBER 22, 2022</span>
                  <h4>
                    <a href="/#">call of duty</a>
                  </h4>
                </div>
                <div className="uc-game-price">
                  <h5>$19</h5>
                </div>
              </div>
              <p>
                Compete with players remote island winner takes showdown known
                issue.
              </p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/images/upcoming_game_thumb02.jpg" alt="" />
                <div className="upcoming-game-cart">
                  <a href="/#" className="btn transparent-btn">
                    <i className="fas fa-shopping-basket" />
                    BUY Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="upcoming-game-item mb-40">
              <div className="upcoming-game-head">
                <div className="uc-game-head-title">
                  <span>SEPTEMBER 22, 2022</span>
                  <h4>
                    <a href="/#">sky hunter</a>
                  </h4>
                </div>
                <div className="uc-game-price">
                  <h5>$19</h5>
                </div>
              </div>
              <p>
                Compete with players remote island winner takes showdown known
                issue.
              </p>
              <div className="upcoming-game-thumb">
                <img src="assets/img/images/upcoming_game_thumb03.jpg" alt="" />
                <div className="upcoming-game-cart">
                  <a href="/#" className="btn transparent-btn">
                    <i className="fas fa-shopping-basket" />
                    BUY Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReleaseGame;
