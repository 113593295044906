import React from "react";
import "./../../assets/css/timeline.css";

function Timeline() {
  return (
    <>
      <div className="container mb-55">
        <div className="col-12">
          <div className="section-title title-style-three text-center mb-70">
            <h2>
              Project <span>Roadmap</span>
            </h2>
          </div>
        </div>
        <ul className="timeline">
          <li>
            <div className="timeline-badge"></div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4 className="timeline-title">Q4 2022</h4>
              </div>
              <div className="timeline-body">
                <p>
                  <ul>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>
                      Initialization stage{" "}
                    </li>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>Founding
                      Team Formation{" "}
                    </li>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>Project
                      Scope Confirmed{" "}
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </li>
          <li className="timeline-inverted">
            <div className="timeline-badge"></div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4 className="timeline-title">Q1 2023</h4>
              </div>
              <div className="timeline-body">
                <p>
                  <ul>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>Team
                      Formation{" "}
                    </li>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>Fund
                      Raising{" "}
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </li>
          <li>
            <div className="timeline-badge"></div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4 className="timeline-title">Q2 2023</h4>
              </div>
              <div className="timeline-body">
                <p>
                  <ul>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>Game
                      studio set up{" "}
                    </li>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>PlayArea
                      Games players portal; Beta launch{" "}
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </li>

          <li className="timeline-inverted">
            <div className="timeline-badge"></div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4 className="timeline-title">Q3 2023</h4>
              </div>
              <div className="timeline-body">
                <p>
                  <ul>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>PlayArea
                      Market Place - Game Launch;{" "}
                    </li>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>FeArea
                      Beta testing{" "}
                    </li>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>Game
                      Launch; Humanoid battle Beta testing{" "}
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </li>
          <li>
            <div className="timeline-badge"></div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4 className="timeline-title">Q4 2023</h4>
              </div>
              <div className="timeline-body">
                <p>
                  <ul>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>IGO/INO
                      Launchpad{" "}
                    </li>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>Game
                      Launch
                    </li>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>
                      Reserve Island; beta testing{" "}
                    </li>
                    <li>
                      <span style={{ color: "#e4a101" }}>&diams; </span>Play
                      poker; beta testing{" "}
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Timeline;
