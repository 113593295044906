import { BrowserRouter as Router, Switch } from "react-router-dom";
import HomeTwo from "./pages/homes/HomeTwo";

import Store from "./pages/store/Store";
import StoreDetails from "./pages/store/StoreDetails";
// import Contact from "./pages/contact/Contact";
// import Community from "./pages/community/Community";
import Overview from "./pages/overview/Overview";
// GAMES
import AboutGame from "./pages/game/aboutgame/AboutGame";
import Fearea from "./pages/feareagame/GameSingle";
import Blogs from "./pages/blogdetails/BlogDetails";
import Token from "./pages/token/Token";
import AboutUs from "./pages/aboutpage/aboutuspage/AboutUs";
import Node from "./pages/nodepage/nodepage/Node";
import Upcoming from "./pages/aboutpage/upcoming/Upcoming";

//AUTH ROUTES
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import VerifyAccount from "./pages/auth/VerifyAccount";
import ConfirmAccount from "./pages/auth/ConfirmAccount";
import Dashboard from "./pages/auth/dashboard/Dashboard";

import AuthRoute from "./utils/AuthRoute";
import ScrollToTopRoute from "./components/scrolltotop/ScrollToTopRoute";
import ForgotPassword from "./pages/auth/ForgotPassword";
import CompleteRecovery from "./pages/auth/CompleteRecovery";
import ChangePassword from "./pages/auth/ChangePassword";
import Notification from "./pages/auth/dashboard/Notification";
import Pin from "./pages/auth/dashboard/Pin";
import Password from "./pages/auth/dashboard/Password";
import DeleteAccount from "./pages/auth/dashboard/Delete";
import Faq from "./pages/auth/dashboard/Faq";
import Deposit from "./pages/auth/dashboard/Deposit";
import Balances from "./pages/auth/dashboard/Balances";
import SubmitTicket from "./pages/auth/dashboard/SubmitTicket";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path="/">
            <HomeTwo />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/store">
            <Store />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/token">
            <Token />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/store/buy-item/:name">
            <StoreDetails />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/overview">
            <Overview />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/blog">
            <Blogs />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/about-us">
            <AboutUs />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/node">
            <Node />
          </ScrollToTopRoute>
          {/* GAME ROUTES */}
          <ScrollToTopRoute exact={true} path="/game/fearea">
            <AboutGame />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/game/fearea-details">
            <Fearea />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/upcoming-games">
            <Upcoming />
          </ScrollToTopRoute>
          {/* AUTHENTICATED ROUTES */}
          <ScrollToTopRoute exact={true} path="/auth/login">
            <Login />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/auth/register">
            <Register />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/auth/verify_account">
            <VerifyAccount />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/auth/confirm_account">
            <ConfirmAccount />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/auth/forgot_password">
            <ForgotPassword />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/auth/complete_recovery">
            <CompleteRecovery />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/auth/change_password">
            <ChangePassword />
          </ScrollToTopRoute>
          {/* AUTHENTICATED ROUTES */}
          {/* DASHBOARD ROUTES STARTS */}
          <AuthRoute exact={true} path="/dashboard">
            <Dashboard />
          </AuthRoute>
          <AuthRoute exact={true} path="/dashboard/display_name">
            <Dashboard />
          </AuthRoute>
          <AuthRoute exact={true} path="/dashboard/wallet_address">
            <Deposit />
          </AuthRoute>
          <AuthRoute exact={true} path="/dashboard/balances">
            <Balances />
          </AuthRoute>
          <AuthRoute exact={true} path="/dashboard/change_notification">
            <Notification />
          </AuthRoute>
          <AuthRoute exact={true} path="/dashboard/transaction_pin">
            <Pin />
          </AuthRoute>
          <AuthRoute exact={true} path="/dashboard/password">
            <Password />
          </AuthRoute>
          <AuthRoute exact={true} path="/dashboard/delete_account">
            <DeleteAccount />
          </AuthRoute>
          <AuthRoute exact={true} path="/dashboard/faq">
            <Faq />
          </AuthRoute>
          <AuthRoute exact={true} path="/dashboard/submit_ticket">
            <SubmitTicket />
          </AuthRoute>
          {/* DASHBOARD ROUTES ENDS */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
