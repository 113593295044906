import React, { useEffect } from "react";
import WOW from "wowjs";

function AboutInner() {
  useEffect(() => {
    new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
      loop: Infinity,
    }).init();
  }, []);
  return (
    <section className="inner-about-area fix">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
            <div className="inner-about-img">
              <img
                src="https://imgur.com/Xi6FtLd.jpg"
                className="wow fadeInRight"
                data-wow-delay=".3s"
                alt=""
              />
              <img
                src="https://imgur.com/bl1NvEk.png"
                className="wow fadeInLeft"
                data-wow-delay=".6s"
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="section-title title-style-three mb-25">
              <h2>
                Fearea <span></span>
              </h2>
            </div>
            <div className="inner-about-content">
              <h5>Battle Royale</h5>
              <p>
                Fearea is a fast-paced action game, in which robots fight in a
                fierce arena battle. The "strategic" camera angle, a lot of
                gunfire, and the explosions of powerful, advanced weapons all
                give the Battle Royale gameplay a unique twist.
              </p>

              <a
                href="https://fearea.com"
                className="btn btn-style-two"
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="inner-about-shape">
        <img src="assets/img/images/medale_shape.png" alt="" />
      </div> */}
    </section>
  );
}

export default AboutInner;
