import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import ScrollToTop from "react-scroll-to-top";
import { AuthContext } from "./../context/Auth";

export default function HeaderTwo() {
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .push-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );

      $(".menu-backdrop, .mobile-menu .close-btn").click(() => {
        $("body").removeClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });
    }
  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
      }
    });
  }, []);

  return (
    <header>
      <ScrollToTop smooth top="500" color="#000" />
      <div className="header-top-area s-header-top-area d-none d-lg-block">
        <div className="container-fluid s-container-full-padding">
          <div className="row align-items-center">
            <div className="col-lg-6 d-none d-lg-block">
              <div className="header-top-offer">
                <select
                  style={{
                    padding: ".2em",
                    backgroundColor: "#312620",
                    color: "white",
                  }}
                >
                  <option value="">English</option>
                  {/* <option value="">Chinese</option>
                  <option value="">Spanish</option> */}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-top-right">
                <div className="header-social">
                  <ul>
                    {/* <li>
                      <Link to="/#">
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li> */}
                    <li>
                      <Link to="https://twitter.com/PlayAreaGame">
                        <i className="fab fa-twitter" />
                      </Link>
                    </li>

                    <li>
                      <Link to="https://t.me/PlayAreaGames">
                        <i className="fab fa-telegram" />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="header-top-action">
                  <ul>
                    <li>
                      <div className="header-top-mail">
                        <p>
                          <span>|</span>
                          <i className="far fa-envelope" />
                          <Link to="/mailto:info@playarea.com">
                            info@playarea.com
                          </Link>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sticky-header" className="transparent-header menu-area">
        <div className="container-fluid s-container-full-padding">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler two">
                <i className="fas fa-bars" />
              </div>
              <div className="main-menu  menu-style-two">
                <nav>
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="https://imgur.com/BOqFiqZ.png"
                        alt="play area logo"
                        width="60%"
                        height="60%"
                      />
                    </Link>
                  </div>
                  <div className="navbar-wrap push-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About</Link>
                      </li>
                      <li>
                        <Link to="/token">Token</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="#">Games</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/game/fearea">Fearea</Link>
                          </li>
                          {/* <li>
                            <Link to="#">Upcoming Games</Link>
                          </li> */}
                          {/* <li>
                            <Link to="/game-single">Game Single</Link>
                          </li> */}
                        </ul>
                      </li>

                      <li>
                        <Link to="/node">Node</Link>
                      </li>
                      {/* <li>
                        <Link to="/overview">Overview</Link>
                      </li>
                      <li>
                        <Link to="/community">Community</Link>
                      </li> */}
                      <li>
                        <Link to="/store">Market Place</Link>
                      </li>
                      <li className="menu-item-has-children">
                        {!currentUser ? (
                          <>
                            <Link to="#">Account</Link>
                            <ul className="submenu">
                              <li>
                                <Link to="/auth/login">
                                  <span>
                                    {" "}
                                    <i className="fab fa-user mr-12" />
                                  </span>
                                  Login
                                </Link>
                              </li>
                              <li>
                                <Link to="/auth/register">
                                  <span>
                                    {" "}
                                    <i className="fab fa-user-plus mr-12" />
                                  </span>
                                  Register
                                </Link>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <Link to="/dashboard">Dashboard</Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <Link to="/">
                      <img src="assets/img/logo/logo.png" alt="" title="true" />
                    </Link>
                  </div>
                  <div className="menu-outer"></div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <a href="/#">
                          <span className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-facebook-square" />
                        </a>
                      </li>

                      <li>
                        <a href="/#">
                          <span className="fab fa-telegram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
            </div>
            {/* Modal Search */}
            <div
              className="modal fade"
              id="search-modal"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <form>
                    <input type="text" placeholder="Search here..." />
                    <button>
                      <i className="fa fa-search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
