import React, { useState, useEffect } from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import data from "../../utils/data.json";
import { getSortedNfts } from "../../utils/Filter";

function StoreArea() {
  const [nfts, setNfts] = useState(data);
  const [playarea, setPlayarea] = useState(true);
  const [fearea, setFearea] = useState(true);
  const [searchGames, setSearchGames] = useState("");
  const [searchAvailable, setSearchAvailable] = useState("");
  const [searchRarity, setSearchRarity] = useState("");

  useEffect(() => {
    // if (searchGames === "") {
    //   return setNfts(data);
    // }
    if (searchGames === "" && searchRarity === "") {
      return setNfts(data);
    }
    //first
    if (
      searchGames === "Playarea" &&
      searchRarity === "https://imgur.com/kCI9XDR.png"
    ) {
      let sortedNfts = data.filter(
        (product) =>
          product.rarity === "https://imgur.com/kCI9XDR.png" &&
          product.game === "Playarea"
      );
      return setNfts(sortedNfts);
    }
    if (
      searchGames === "Fearea" &&
      searchRarity === "https://imgur.com/kCI9XDR.png"
    ) {
      let sortedNfts = data.filter(
        (product) =>
          product.rarity === "https://imgur.com/kCI9XDR.png" &&
          product.game === "Fearea"
      );
      return setNfts(sortedNfts);
    }
    if (
      searchGames === "" &&
      searchRarity === "https://imgur.com/kCI9XDR.png"
    ) {
      let sortedNfts = data.filter(
        (product) => product.rarity === "https://imgur.com/kCI9XDR.png"
      );
      return setNfts(sortedNfts);
    }
    //second
    if (
      searchGames === "Playarea" &&
      searchRarity === "https://imgur.com/eKjRteB.png"
    ) {
      let sortedNfts = data.filter(
        (product) =>
          product.rarity === "https://imgur.com/eKjRteB.png" &&
          product.game === "Playarea"
      );
      return setNfts(sortedNfts);
    }
    if (
      searchGames === "Fearea" &&
      searchRarity === "https://imgur.com/eKjRteB.png"
    ) {
      let sortedNfts = data.filter(
        (product) =>
          product.rarity === "https://imgur.com/eKjRteB.png" &&
          product.game === "Fearea"
      );
      return setNfts(sortedNfts);
    }
    if (
      searchGames === "" &&
      searchRarity === "https://imgur.com/eKjRteB.png"
    ) {
      let sortedNfts = data.filter(
        (product) => product.rarity === "https://imgur.com/eKjRteB.png"
      );
      return setNfts(sortedNfts);
    }

    //third
    if (
      searchGames === "Playarea" &&
      searchRarity === "https://imgur.com/rGSwNg4.png"
    ) {
      let sortedNfts = data.filter(
        (product) =>
          product.rarity === "https://imgur.com/rGSwNg4.png" &&
          product.game === "Playarea"
      );
      return setNfts(sortedNfts);
    }
    if (
      searchGames === "Fearea" &&
      searchRarity === "https://imgur.com/rGSwNg4.png"
    ) {
      let sortedNfts = data.filter(
        (product) =>
          product.rarity === "https://imgur.com/rGSwNg4.png" &&
          product.game === "Fearea"
      );
      return setNfts(sortedNfts);
    }
    if (
      searchGames === "" &&
      searchRarity === "https://imgur.com/rGSwNg4.png"
    ) {
      let sortedNfts = data.filter(
        (product) => product.rarity === "https://imgur.com/rGSwNg4.png"
      );
      return setNfts(sortedNfts);
    }
    let sortedNfts = getSortedNfts(data, searchGames, searchRarity);
    setNfts(sortedNfts);
  }, [searchGames, searchRarity]);

  return (
    <>
      <main>
        {/* <BreadCrumb /> */}
        <section className="shop-area black-bg pt-115 pb-90">
          <div className="container">
            <div className="row justify-content-center pt-115">
              <div className="col-xl-6 col-lg-8">
                <div className="section-title title-style-three white-title text-center mb-40">
                  <h2>
                    Market <span>Place</span>
                  </h2>
                  <p>Buy NFT's, Games, Nodes, Collectables and many more </p>
                </div>
              </div>
            </div>
            <div className="row product-active">
              <div className="col-xl-3 col-lg-3 ">
                <section>
                  <div className="fw-title mb-10 ">
                    <h5 className="storethings">Games</h5>
                  </div>

                  <p>
                    <span className="mr-2">
                      <select
                        onChange={(e) => setSearchGames(e.target.value)}
                        style={{
                          padding: ".2em",
                          backgroundColor: "#fffff",
                          fontWeight: "bold",
                        }}
                      >
                        <option value="">All</option>
                        <option value="Fearea">Fearea</option>
                        <option value="Playarea">Playarea</option>
                      </select>
                    </span>
                  </p>
                </section>
                <section>
                  <div className="fw-title mb-10 mt-35">
                    <h5>Rarity</h5>
                  </div>

                  <p>
                    {searchRarity === "https://imgur.com/rGSwNg4.png" ? (
                      <span
                        style={{ color: "#E4A101" }}
                        onClick={() => setSearchRarity("")}
                      >
                        {" "}
                        Common
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          setSearchRarity("https://imgur.com/rGSwNg4.png")
                        }
                      >
                        {" "}
                        Common
                      </span>
                    )}

                    <span className="m-2">
                      <img
                        src="https://imgur.com/rGSwNg4.png"
                        width="20px"
                        height="20px"
                        alt="playarea"
                      />
                    </span>
                  </p>

                  <p>
                    {searchRarity === "https://imgur.com/eKjRteB.png" ? (
                      <span
                        style={{ color: "#E4A101" }}
                        onClick={() => setSearchRarity("")}
                      >
                        {" "}
                        Epic
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          setSearchRarity("https://imgur.com/eKjRteB.png")
                        }
                      >
                        {" "}
                        Epic
                      </span>
                    )}

                    <span className="m-2">
                      <img
                        src="https://imgur.com/eKjRteB.png"
                        width="20px"
                        height="20px"
                        alt="playarea"
                      />
                    </span>
                  </p>

                  <p>
                    {searchRarity === "https://imgur.com/kCI9XDR.png" ? (
                      <span
                        style={{ color: "#E4A101" }}
                        onClick={() => setSearchRarity("")}
                      >
                        {" "}
                        Legend
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          setSearchRarity("https://imgur.com/kCI9XDR.png")
                        }
                      >
                        {" "}
                        Legend
                      </span>
                    )}

                    <span className="m-2">
                      <img
                        src="https://imgur.com/kCI9XDR.png"
                        width="20px"
                        height="20px"
                        alt="playarea"
                      />
                    </span>
                  </p>
                </section>

                {/* <section>
                  <div className="fw-title mb-10 mt-35">
                    <h5>Availability</h5>
                  </div>
                  <p>
                    <span className="mr-2">
                      <select
                        onChange={(e) => setSearchAvailable(e.target.value)}
                        style={{
                          padding: ".2em",
                          backgroundColor: "#fffff",
                          color: "#E$A101",
                        }}
                      >
                        <option value="">All</option>
                        <option value="  Common">Sold Out </option>
                        <option value="Epic">Available</option>
                      </select>
                    </span>
                  </p>
                  <p>
                    <span className="mr-2">
                      <input type="checkbox" id="" name=""></input>
                    </span>
                    Sold Out
                  </p>
                  <p>
                    <span className="mr-2">
                      <input type="checkbox" id="" name=""></input>
                    </span>
                    Available
                  </p>
                </section> */}
              </div>
              <div className="col-xl-9 col-lg-9 ">
                <div className="row">
                  {nfts != null ? (
                    <>
                      {nfts.map((single) => (
                        <div
                          className="col-xl-6 col-lg-6 mt-10"
                          key={single.name}
                        >
                          <Link to={"/store/buy-item/" + single.name}>
                            <div className="shop-item">
                              <div className="product-thumb">
                                <img
                                  src={single.url}
                                  alt={`${single.name} Fearea Character`}
                                  width="60%"
                                  height="60%"
                                />
                              </div>
                              <div className="product-content">
                                <div className="product-tag">
                                  <Link> {single.game}</Link>
                                </div>
                                <h4>
                                  <Link>{single.name}</Link>
                                </h4>
                                <div className="mb-30">
                                  <p>
                                    {single.reserved}/{single.quantity} IN
                                    CURRENT TIER
                                  </p>
                                </div>
                                <div className="product-meta">
                                  <div className="product-price">
                                    <h5>{single.amount} PLAY</h5>
                                  </div>
                                  <div className="product-cart-action">
                                    <img
                                      src={single.rarity}
                                      width="30px"
                                      height="30px"
                                      alt="playarea"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>nothing</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default StoreArea;
