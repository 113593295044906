import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBBtn,
} from "cdbreact";
import { NavLink } from "react-router-dom";
import "./sidebar.css";

const Sidebar = () => {
  return (
    <div
      className={`app`}
      style={{ display: "flex", height: "100%", overflow: "scroll initial" }}
    >
      <CDBSidebar textColor="white" backgroundColor="#252525">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            <div className="logo">
              <img
                src="https://imgur.com/BOqFiqZ.png"
                alt="play area logo"
                width="60%"
                height="60%"
              />
            </div>
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink
              exact
              to="/dashboard/display_name"
              activeClassName="activeClicked"
              className="nav-item"
            >
              <CDBSidebarMenuItem icon="user-circle" className="item">
                Display Name
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/dashboard/wallet_address"
              activeClassName="activeClicked"
              className="nav-item"
            >
              <CDBSidebarMenuItem icon="address-card" className="item">
                Deposit
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/dashboard/balances"
              activeClassName="activeClicked"
              className="nav-item"
            >
              <CDBSidebarMenuItem icon="wallet" className="item">
                Balances
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/dashboard/change_notification"
              activeClassName="activeClicked"
              className="nav-item"
            >
              <CDBSidebarMenuItem icon="bell" className="item">
                Notifications
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/dashboard/transaction_pin"
              activeClassName="activeClicked"
              className="nav-item"
            >
              <CDBSidebarMenuItem icon="fingerprint" className="item">
                Transaction Pin
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/dashboard/password"
              activeClassName="activeClicked"
              className="nav-item"
            >
              <CDBSidebarMenuItem icon="user-lock" className="item">
                Password
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/dashboard/delete_account"
              activeClassName="activeClicked"
              className="nav-item"
            >
              <CDBSidebarMenuItem icon="trash" className="item">
                Delete Account
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/dashboard/faq"
              activeClassName="activeClicked"
              className="nav-item"
            >
              <CDBSidebarMenuItem icon="user-shield" className="item">
                FAQ
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/dashboard/submit_ticket"
              activeClassName="activeClicked"
              className="nav-item"
            >
              <CDBSidebarMenuItem icon="table" className="item">
                Submit a Ticket
              </CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
          <CDBSidebarMenu></CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
