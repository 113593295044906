import React from "react";

function GamesArea() {
  return (
    <section className="featured-game-area position-relative pt-115 pb-90">
      <div className="featured-game-bg" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-three text-center mb-70">
              <h2>
                PLAYAREA <span>ECOSYSTEM</span>
              </h2>
              <p> </p>
            </div>
          </div>
        </div>
        <div className="row featured-active">
          <div className="col-lg-12 col-sm-12 grid-item">
            <div className="featured-game-item mb-30">
              <div className="featured-game-thumb">
                <img
                  src="https://i.imgur.com/ikyMdlI.gif"
                  alt="playarea ecosystem"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GamesArea;
