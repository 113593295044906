import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../../../components/context/Auth";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "./Dashboard.css";
import Footer from "../../../components/footer/Footer";
import { Button, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";

const Notification = () => {
  //  States
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState("");
  const { currentUser } = useContext(AuthContext);

  const onUpdateNotification = () => {
    const formData = {
      status: notification,
    };
    try {
      const url = `${process.env.REACT_APP_SERVER_API_URL}/dashboard/notifications`;
      const options = {
        method: "POST",
        headers: {
          Authorization: currentUser,
          "content-type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(formData),
        url,
      };
      axios(options)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(response.data.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(response.data.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            console.log(response);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        });
    } catch (err) {
      toast.error(`a server error occurred ${err}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    console.log(notification);
  }, [notification]);

  return (
    <>
      {/* <HeaderTwo /> */}
      <div className="dashboard d-flex">
        <div>
          <Sidebar />
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <Navbar />
          <div style={{ height: "100%" }}>
            <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
              <div className="d-flex card-section">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12" style={{ marginTop: "2rem" }}>
                      <div className="blog-list-post" id="name">
                        <div className="blog-list-post-content">
                          <div className="blog-list-post-tag mb-25">
                            <center>
                              <div className="section-title newsletter-title">
                                {" "}
                                <h2>Notifications</h2>
                              </div>
                              <p>Update your notifications settings here.</p>
                            </center>
                          </div>
                          <form
                            className="comment-form"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              <div className="col-md-12 mb-25">
                                <div
                                  className="switch"
                                  onChange={(e) =>
                                    setNotification(e.target.value)
                                  }
                                >
                                  <input
                                    type="radio"
                                    className="switch-input"
                                    name="view"
                                    value="on"
                                    id="on"
                                  />
                                  <label
                                    htmlFor="on"
                                    className="switch-label switch-label-off"
                                  >
                                    ON
                                  </label>
                                  <input
                                    type="radio"
                                    className="switch-input"
                                    name="view"
                                    value="off"
                                    id="off"
                                  />
                                  <label
                                    htmlFor="off"
                                    className="switch-label switch-label-on"
                                  >
                                    OFF
                                  </label>
                                  <span className="switch-selection"></span>
                                </div>
                              </div>

                              <center>
                                {loading ? (
                                  <Button color="warning" disabled>
                                    <Spinner size="sm">Updating...</Spinner>
                                    <span> Updating</span>
                                  </Button>
                                ) : (
                                  <Button
                                    color="warning"
                                    style={{
                                      textTransform: "capitalize",
                                      fontWeight: "bold",
                                      fontSize: "1.2em",
                                      paddingTop: ".6em",
                                      paddingBottom: ".6em",
                                      paddingLeft: "2em",
                                      paddingRight: "2em",
                                    }}
                                    onClick={onUpdateNotification}
                                  >
                                    Update
                                  </Button>
                                )}
                              </center>
                            </div>
                          </form>
                        </div>
                        <div className="blog-list-post-bottom"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer style={{ zIndex: "100" }} />
    </>
  );
};

export default Notification;
