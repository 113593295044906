import React, { useState, useContext } from "react";
import { AuthContext } from "./../../../components/context/Auth";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "./Dashboard.css";
import Footer from "../../../components/footer/Footer";
import { Button, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";

const Password = () => {
  //  States
  const [pin, setPin] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const onUpdatePassword = () => {
    if (password === "" || confirmPassword === "" || pin === "") {
      toast.error("Please enter a transaction pin", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (password !== confirmPassword) {
      toast.error("Pin did not match", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(true);
      const formData = {
        confirmPassword,
        password,
        pin,
      };
      try {
        const url = `${process.env.REACT_APP_SERVER_API_URL}/dashboard/account_password`;
        const options = {
          method: "POST",
          headers: {
            Authorization: currentUser,
            "content-type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(formData),
          url,
        };
        axios(options)
          .then((response) => {
            console.log(response);
            if (response.data.success === true) {
              toast.success(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setLoading(false);
            } else {
              setLoading(false);
              toast.error(response.data.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              console.log(response);
            }
          })
          .catch((err) => {
            toast.error(err.response.data.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          });
      } catch (err) {
        toast.error(`a server error occurred ${err}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {/* <HeaderTwo /> */}
      <div className="dashboard d-flex">
        <div>
          <Sidebar />
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <Navbar />
          <div style={{ height: "100%" }}>
            <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
              <div className="d-flex card-section">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12" style={{ marginTop: "2rem" }}>
                      <div className="blog-list-post" id="name">
                        <div className="blog-list-post-content">
                          <div className="blog-list-post-tag mb-25">
                            <center>
                              <div className="section-title newsletter-title">
                                {" "}
                                <h2>Change Password</h2>
                              </div>
                              <p>
                                To update your account password, please enter
                                your old password and enter a new password.
                              </p>
                            </center>
                          </div>
                          <form
                            className="comment-form"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              <div className="col-md-12 mb-25">
                                <input
                                  type="password"
                                  placeholder="Enter your default or transaction pin"
                                  value={pin}
                                  onChange={(e) => setPin(e.target.value)}
                                  required
                                  maxLength={4}
                                />
                              </div>
                              <div className="col-md-12 mb-25">
                                <input
                                  type="password"
                                  placeholder="Enter a new  password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                  minLength={6}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-25">
                                <input
                                  type="password"
                                  placeholder="Confirm new password"
                                  value={confirmPassword}
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                  required
                                  minLength={6}
                                />
                              </div>

                              <center>
                                {loading ? (
                                  <Button color="warning" disabled>
                                    <Spinner size="sm">Updating...</Spinner>
                                    <span> Updating</span>
                                  </Button>
                                ) : (
                                  <Button
                                    color="warning"
                                    style={{
                                      textTransform: "capitalize",
                                      fontWeight: "bold",
                                      fontSize: "1.2em",
                                      paddingTop: ".6em",
                                      paddingBottom: ".6em",
                                      paddingLeft: "2em",
                                      paddingRight: "2em",
                                    }}
                                    onClick={onUpdatePassword}
                                  >
                                    Change
                                  </Button>
                                )}
                              </center>
                            </div>
                          </form>
                        </div>
                        <div className="blog-list-post-bottom"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>

      <Footer style={{ zIndex: "100" }} />
    </>
  );
};

export default Password;
