import React, { useState, useEffect } from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import data from "../../utils/data.json";
import { getSortedNfts } from "../../utils/Filter";
import StoreArea from "./StoreArea";

function Store() {
  return (
    <>
      <HeaderTwo />
      <StoreArea />
      <Footer />
    </>
  );
}

export default Store;
