import React, { useState } from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import data from "../../utils/data.json";
import { useParams } from "react-router-dom";

function StoreDetails() {
  // const n = 2707.854;
  // const num = Number(n.toLocaleString("en-US"));
  const [count, setCount] = useState(1);
  const [price, setPrice] = useState(0);
  const [fData, setFData] = useState({});

  let { name } = useParams();

  useEffect(() => {
    const single = data.find((one) => {
      return one.name === name;
    });
    setFData(single);
    console.log(single);
    setPrice(single.amount);
  }, [fData]);
  return (
    <>
      <HeaderTwo />
      <main>
        {/* <BreadCrumb /> */}
        <section className="shop-area black-bg pt-115 pb-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="  white-title text-center mb-40  pt-115"></div>
              </div>
            </div>
            <div className="row product-active">
              <div className="col-xl-8 col-lg-8">
                <div className="shop-item">
                  <div className="product-content">
                    <div className="product-tag">
                      <Link to="/#">{fData.game}</Link>
                    </div>
                    <h4>
                      <Link to="/#">{fData.name}</Link>
                      <span className="ml-60">
                        <img
                          src={fData.rarity}
                          width="30px"
                          height="30px"
                          alt={fData.name}
                        />
                      </span>
                    </h4>
                  </div>
                  <div className="product-thumb">
                    <Link to="/#">
                      <img src={fData.url} alt={fData.game} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 ">
                <center>
                  <section>
                    <div className="mt-30 ">
                      <h5 className="storethings">Select Currency</h5>
                      <hr
                        style={{
                          border: "3px solid #e4a101",
                          width: "20%",
                          borderRadius: "5px",
                        }}
                      ></hr>
                    </div>
                    <p> AREA</p>
                  </section>
                  <section>
                    <div className="mt-35">
                      <h5>price</h5>
                      <hr
                        style={{
                          border: "3px solid #e4a101",
                          width: "20%",
                          borderRadius: "5px",
                        }}
                      ></hr>
                    </div>
                    <p>{fData.amount * count}</p>
                  </section>

                  <section>
                    <div className="mt-35">
                      <h5>Quantity</h5>
                    </div>
                    <hr
                      style={{
                        border: "3px solid #e4a101",
                        width: "20%",
                        borderRadius: "5px",
                      }}
                    ></hr>

                    <div className="pro-content">
                      <div className="tag">
                        <p style={{ fontSize: "2em" }}>
                          {" "}
                          {count > 1 ? (
                            <i
                              className="fas fa-caret-square-down"
                              onClick={(e) => {
                                setCount(count - 1);
                                setPrice(price * count);
                              }}
                            />
                          ) : (
                            <i className="fas fa-caret-square-down" />
                          )}{" "}
                          {count}{" "}
                          <i
                            className="fas fa-caret-square-up"
                            onClick={(e) => setCount(count + 1)}
                          />
                        </p>
                      </div>
                    </div>
                  </section>
                  <section className="mt-60">
                    <a href="#" className="btn btn-style-two">
                      Place Order
                    </a>
                  </section>
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default StoreDetails;
