import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "./../../../components/context/Auth";
import Sidebar from "../../../Sidebar";
import Navbar from "../../../Navbar";
import "./Dashboard.css";
import Footer from "../../../components/footer/Footer";
import { Button, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";

const Dashboard = () => {
  //  States
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  // const [password, setPassword] = useState("");
  let history = useHistory();

  const onUpdateAddress = () => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_SERVER_API_URL}/dashboard/deposit_address`;
      const options = {
        method: "GET",
        headers: {
          Authorization: currentUser,
          "content-type": "application/x-www-form-urlencoded",
        },
        url,
      };
      axios(options)
        .then((response) => {
          if (response.data.success === true) {
            setAddress(response.data.data);
            toast.success(response.data.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(response.data.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
        });
    } catch (err) {
      toast.error(`a server error occurred ${err}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    onUpdateAddress();
  }, []);

  return (
    <>
      {/* <HeaderTwo /> */}
      <div className="dashboard d-flex">
        <div>
          <Sidebar />
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "column",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <Navbar />
          <div style={{ height: "100%" }}>
            <div style={{ height: "calc(100% - 64px)", overflowY: "scroll" }}>
              <div className="d-flex card-section">
                <div className="container mt-40">
                  <div className="row">
                    <div className="col-md-12 mb-25">
                      <div className="blog-list-post-tag mb-25">
                        <center>
                          <div className="section-title newsletter-title">
                            {" "}
                            <h2 style={{ color: "black" }}>Wallet Address</h2>
                          </div>
                          <p style={{ color: "black" }}>
                            Please only send ERC20 tokens to this address to
                            avoid loss of funds.
                          </p>
                        </center>
                      </div>
                    </div>
                    <div className="col-md-12 mb-25">
                      <div className="community-wrap">
                        <div className="table-responsive-xl">
                          <table className="table mt-0">
                            <thead>
                              <tr>
                                <th scope="col">Token</th>
                                <th scope="col">Address</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">
                                  <div className="community-post-wrap">
                                    <div className="community-post-content">
                                      <h3 style={{ color: "black" }}>
                                        Ethereum
                                      </h3>
                                    </div>
                                    <div className="community-post-tag">
                                      <h3 style={{ color: "black" }}>ETH</h3>
                                    </div>
                                  </div>
                                </th>
                                <td>
                                  <h4 style={{ color: "black" }}>{address}</h4>
                                </td>
                                <td className="community-bell">
                                  <i
                                    className="fas fa-copy"
                                    style={{ fontSize: "2rem" }}
                                    onClick={() => {
                                      var fake =
                                        document.createElement("input");
                                      document.body.appendChild(fake);
                                      fake.value = `${address}`;
                                      fake.select();
                                      fake.setSelectionRange(0, 99999);
                                      navigator.clipboard.writeText(fake.value);
                                      toast.success("Link Copied ", {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      });
                                      document.body.removeChild(fake);
                                    }}
                                  ></i>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer style={{ zIndex: "100" }} />
    </>
  );
};

export default Dashboard;
